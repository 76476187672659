
let currentValues = Array.from(document.getElementById("emoji-selector").value);
let currentDeadChar = currentValues[0];
let currentLiveChar = currentValues[1];

function changeEmoji(e) {
    const newValuesString = e.target.value;
    const newValues = Array.from(newValuesString);
    const deadChar = newValues[0];
    const liveChar = newValues[1];

    const styleElement = document.getElementById("emoji-styles");
    const styles = styleElement.innerText

    const newStyles = styles
        .replace(
            `.cell-state-0:after{content:"${currentDeadChar}"}`,
            `.cell-state-0:after{content:"${deadChar}"}`
        )
        .replace(
            `.cell-state-1:after{content:"${currentLiveChar}"}`,
            `.cell-state-1:after{content:"${liveChar}"}`
        );

    styleElement.innerText = newStyles;

    currentDeadChar = deadChar;
    currentLiveChar = liveChar;
}

export function initializeSettings(
    start: () => void,
    pause: () => void,
    clear: () => void,
    randomize: () => void
) {
    document.getElementById("start-button").onclick = start;
    document.getElementById("pause-button").onclick = pause;
    document.getElementById("clear-button").onclick = clear;
    document.getElementById("randomize-button").onclick = randomize;
    document.getElementById("emoji-selector").addEventListener("change", changeEmoji);
}
