import { CellGrid } from './cell';
import { Cell } from './cell';

export type CellElementMap = {[id: string]: HTMLElement};

export function createElement<K extends keyof HTMLElementTagNameMap>(tag: K, props?: any): HTMLElementTagNameMap[K] {
    const element = document.createElement(tag);

    if (props) {
        for (const key of Object.keys(props)) {
            element.setAttribute(key, props[key]);
        }
    }

    return element;
}

export function getCellClasses({state, isVisible}: Cell): string {
    return `cell cell-state-${state} cell-visibility-${isVisible}`;
}

export function paintCell(cell: Cell, element: HTMLElement) {
    if (element) {
        element.setAttribute("class", getCellClasses(cell));
    } else {
        console.error('No element to paint:', cell.elementId, cell);
    }
}

export function paintCells(cells: Cell[], cellElements: CellElementMap) {
    for (var cell of cells) {
        paintCell(cell, cellElements[cell.elementId]);
    }
}

export function paintAllCellsInGrid(grid: Grid, cellElements: CellElementMap) {
    const allCells = grid.flat().filter(c => c.isVisible);
    paintCells(allCells, cellElements);
}

export function initializeGrid(
    grid: CellGrid,
    cellSize: number,
    hiddenBufferSize: number,
    handleCellMouseDown: (x: number, y: number) => void,
    handleCellMouseUp: () => void,
    handleCellMouseEnter: (x: number, y: number) => void
) {
    const gridElement = document.getElementById("grid");
    gridElement.style.gridTemplateColumns = `${grid.length - 2 * hiddenBufferSize}`;
    gridElement.style.gridTemplateRows = `${grid[0].length - 2 * hiddenBufferSize}`;
    gridElement.style.gridAutoColumns = `${cellSize}px`;
    gridElement.style.gridAutoRows = `${cellSize}px`;

    const cellElements: CellElementMap = {};

    for (let y = hiddenBufferSize; y < grid.length - hiddenBufferSize; y++) {
        const row = grid[y];

        for (let x = hiddenBufferSize; x < row.length - hiddenBufferSize; x++) {
            const cell = row[x];

            const element = createElement("div", {
                id: cell.elementId,
                class: getCellClasses(cell)
            });

            element.style.gridColumn = `${x - hiddenBufferSize}`;
            element.style.gridRow = `${y - hiddenBufferSize}`;
            element.style.width = `${cellSize}`;
            element.style.height = `${cellSize}`;

            element.addEventListener("mousedown", () => handleCellMouseDown(x, y));
            element.addEventListener("mouseup", () => handleCellMouseUp());
            element.addEventListener("mouseenter", () => handleCellMouseEnter(x, y));

            gridElement.appendChild(element);

            cellElements[cell.elementId] = element;
        }
    }

    return cellElements;
}
