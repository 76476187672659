
export type CellGrid = Array<Array<Cell>>;

type CellState = 0 | 1;

export interface Cell {
    x: number;
    y: number;
    elementId: string;
    state: CellState;
    isVisible: boolean;
    resurrectFailureChance: number;
}

export const FAKE_CELL: Cell = {
    x: -1,
    y: -1,
    elementId: "fake",
    state: randomState(),
    isVisible: false,
    resurrectFailureChance: 1
};

function getCellId(x: number, y: number): string {
    return `cell-${x}-${y}`;
}

function randomState(): CellState {
    return Math.random() > .9 ? 1 : 0
}

function getDistanceFromEdge(x: number, y: number, gridX: number, gridY: number) {
    return Math.min(x, gridX - x, y, gridY - y);
}

export function createCell(x: number, y: number, hiddenWidth: number, gridX: number, gridY: number): Cell {
    const elementId = getCellId(x, y);
    const cellDistanceFromEdge = getDistanceFromEdge(x, y, gridX, gridY);
    const resurrectFailureChance = getResurrectFailureChance(cellDistanceFromEdge, hiddenWidth);

    return {
        x,
        y,
        elementId,
        state: 0,
        isVisible: cellDistanceFromEdge > hiddenWidth,
        resurrectFailureChance
    }
}

function getResurrectFailureChance(cellDistanceFromEdge: number, hiddenWidth: number) {
    if (cellDistanceFromEdge < hiddenWidth) {
        const hiddenDepth = hiddenWidth - cellDistanceFromEdge;
        return hiddenDepth / hiddenWidth;
    }

    return 0;
}

export function getNextState(cell: Cell, liveNeighbors: number): CellState {
    if (cell.state === 1 && liveNeighbors === 2) {
        return 1;
    }
    // GOL rule: 3 neighbors = lives. With exception: no resurrection in the hidden buffers.
    if (liveNeighbors === 3 && (cell.resurrectFailureChance < Math.random() || cell.state === 1)) {
        return 1;
    }
    return 0;
}

export function countLiving(cells: Cell[]): number {
    return cells
        .map((c) => c.state)
        .filter(Boolean).length;
}

function getNeighbors(x: number, y: number, grid: CellGrid): Cell[] {
    const firstX = x === 0;
    const firstY = y === 0;
    const lastY = y === grid.length - 1;
    const lastX = x === grid[0].length - 1;

    return [
        // horizontal and vertical
        !firstX && grid[y][x - 1],
        !lastX && grid[y][x + 1],
        !firstY && grid[y - 1][x],
        !lastY && grid[y + 1][x],
        // diagonals
        !firstX && !firstY && grid[y - 1][x - 1],
        !lastX && !firstY && grid[y - 1][x + 1],
        !firstX && !lastY && grid[y + 1][x - 1],
        !lastX && !lastY && grid[y + 1][x + 1],
        // random fake neighbors at edges
        firstX && FAKE_CELL,
        lastX && FAKE_CELL,
        firstY && FAKE_CELL,
        lastY && FAKE_CELL
    ].filter((v: Cell | false) => v !== false);
}

export function createCellGrid(gridX: number, gridY: number, gridHiddenBufferSize: number) {
    return new Array(gridY)
        .fill(null)
        .map((_, y) =>
            new Array(gridX)
                .fill(null)
                .map((_, x) => createCell(x, y, gridHiddenBufferSize, gridX, gridY))
        );
}

export function forEachCell(grid: Grid, callback: (cell: Cell) => void) {
    for (let y = 0; y < grid.length; y++) {
        const row = grid[y];

        for (let x = 0; x < row.length; x++) {
            const cell = row[x];
            callback(cell);
        }
    }
}

export function randomizeGrid(grid: Grid) {
    forEachCell(grid, (cell: Cell) => {
        cell.state = randomState();
    });
}

export function resetGrid(grid: Grid) {
    forEachCell(grid, (cell: Cell) => {
        cell.state = 0;
    });
}

export function updateGrid(grid: CellGrid, gridHiddenBufferSize: number): Cell[] {
    const updates = [];

    forEachCell(grid, (cell) => {
        const {x, y} = cell;
        const near = getNeighbors(x, y, grid);
        const liveNeighbors = countLiving(near);
        const nextState = getNextState(cell, liveNeighbors);

        grid[y][x] = { ...cell, state: nextState };

        if (cell.state !== nextState) {
            updates.push({ ...cell, state: nextState });
        }
    });

    return updates;
}
