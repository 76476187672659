import { Cell, createCellGrid, updateGrid, randomizeGrid, resetGrid } from "./cell";
import { CellElementMap, createElement, initializeGrid, paintCell, paintCells, paintAllCellsInGrid } from "./dom";
import { initializeSettings } from "./settings";

const CELL_PADDING = 2;
const CELL_SIZE = 16 + 2 * CELL_PADDING;
const CELL_SIZE_WITH_GAP = CELL_SIZE;
const MIN_MARGINS_PX = window.innerWidth < 1200 ? 10 : 32;

const gridTopY = document.getElementById("grid").getBoundingClientRect().y + window.scrollY;

const gridInitialWidth = window.innerWidth - 2 * MIN_MARGINS_PX;
const gridInitialHeight = window.innerHeight - gridTopY - MIN_MARGINS_PX;

const gridVisibleX = Math.floor(gridInitialWidth / CELL_SIZE_WITH_GAP);
const gridVisibleY = Math.floor(gridInitialHeight / CELL_SIZE_WITH_GAP);

const gridHiddenBufferSize = 10;
const gridX = gridVisibleX + 2 * gridHiddenBufferSize;
const gridY = gridVisibleY + 2 * gridHiddenBufferSize;

const grid = createCellGrid(gridX, gridY, gridHiddenBufferSize);
randomizeGrid(grid);

let repaintAllCells = false;

const updateAndPaint = (cellElements: CellElementMap) => {
    const changed = updateGrid(grid, gridHiddenBufferSize);

    if (repaintAllCells) {
        paintAllCellsInGrid(grid, cellElements);
        repaintAllCells = false;
    } else {
        const visibleChanged = changed.filter(cell => cell.isVisible);
        paintCells(visibleChanged, cellElements);
    }
};

interface MouseState {
    x: number;
    y: number;
    state: 0 | 1;
}

const mouseDownState: MouseState | null = null;

const onCellMouseDown = (x: number, y: number) => {
    const cell = grid[y][x];
    const newState = cell.state ? 0 : 1;

    cell.state = newState;
    paintCells([cell], cellElements);

    mouseDownState = {x, y, state: newState};
};

const onCellMouseUp = () => {
    mouseDownState = null;
};

const onCellMouseEnter = (x: number, y: number) => {
    if (mouseDownState) {
        if (mouseDownState.x !== x || mouseDownState.y !== y) {
            const cell = grid[y][x];
            cell.state = mouseDownState.state;
            paintCells([cell], cellElements);
        }
    }
};

let interval: number;
let isPlaying = false;

const cellElements = initializeGrid(
    grid,
    CELL_SIZE,
    gridHiddenBufferSize,
    onCellMouseDown,
    onCellMouseUp,
    onCellMouseEnter
);

const start = () => {
    if (!isPlaying) {
        interval = setInterval(() => updateAndPaint(cellElements), 100);
        isPlaying = true;
    }
};

const pause = () => {
    if (isPlaying) {
        clearInterval(interval);
        isPlaying = false;
    }
};

const clear = () => {
    resetGrid(grid);
    if (isPlaying) {
        repaintAllCells = true;
    } else {
        paintAllCellsInGrid(grid, cellElements);
    }
};

const randomize = () => {
    randomizeGrid(grid);
    if (isPlaying) {
        repaintAllCells = true;
    } else {
        paintAllCellsInGrid(grid, cellElements);
    }
};

initializeSettings(start, pause, clear, randomize);
